import { ApolloClient } from '@apollo/client';
import { useEffect } from 'react';

import {
  GetLoggedInUserDocument,
  useGetLoggedInUserQuery,
  useRefreshUserTokenMutation,
} from '@/generated/graphql';
import Bugsnag from '@/main/bugsnag';

interface UseAuth {
  authenticated: boolean;
  loading: boolean;
  error?: boolean;
  isAdmin: boolean;
}

export const refetchUser = async (
  apolloClient: ApolloClient<object>,
): Promise<void> => {
  await apolloClient.refetchQueries({
    include: [GetLoggedInUserDocument],
  });
};

function useAuth(): UseAuth {
  const token = localStorage.getItem('auth-token');
  const [refresh] = useRefreshUserTokenMutation({
    onCompleted: ({ refresh }) => {
      localStorage.setItem('auth-token', refresh);
    },
  });
  const { data, error, loading, previousData } = useGetLoggedInUserQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const me = data?.me;
      Bugsnag.setUser(me?.id, me?.email, me?.name);
    },
    onError: () => {
      localStorage.removeItem('auth-token');
    },
  });
  const refreshUserToken = async (): Promise<void> => {
    if (token) {
      const lastRefresh = localStorage.getItem('lastRefresh');
      if (!lastRefresh || Date.now() - parseInt(lastRefresh) > 300000) {
        refresh();
        localStorage.setItem('lastRefresh', Date.now().toString());
      }
    }
  };
  useEffect(() => {
    const activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];
    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, refreshUserToken);
    });
    return () => {
      activityEvents.forEach(eventName => {
        document.removeEventListener(eventName, refreshUserToken);
      });
    };
  });

  if (!token) {
    return { authenticated: false, loading: false, isAdmin: false };
  }

  if (loading && !data && !previousData) {
    return { authenticated: false, loading: true, isAdmin: false };
  }

  if (error) {
    Bugsnag.notify(
      new Error(`Connection Error (withUser error): ${error.message}`),
      event => {
        event.severity = 'error';
        event.addMetadata('queryDetails', {
          error,
        });
      },
    );
    return {
      authenticated: false,
      loading: false,
      error: true,
      isAdmin: false,
    };
  }

  const { me } = data ?? previousData;
  return {
    authenticated: !!me,
    loading: false,
    isAdmin: me?.role === 'admin',
  };
}

export default useAuth;
